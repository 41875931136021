<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card @addModal="openDialog" :heading="page.title" :dialog="true" :manage="true" :button="button">
    <v-expansion-panels class="pa-5">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Exibir na tabela
        </v-expansion-panel-header>
        <v-expansion-panel-content>
           <v-row>
              <v-col cols="12" sm="4" md="4">
                  <v-checkbox
                  v-model="filters[1]"
                  label="Status"
                  hide-details
                  @change="changeHeader(1)"
                ></v-checkbox>
                <v-checkbox
                  v-model="filters[2]"
                  label="Nota"
                  hide-details
                  @change="changeHeader(2)"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-checkbox
                  v-model="filters[3]"
                  label="Tempo gasto"
                  hide-details
                  @change="changeHeader(3)"
                ></v-checkbox>
                  <v-checkbox
                  v-model="filters[4]"
                  label="Iniciado em"
                  hide-details
                  @change="changeHeader(4)"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                  <v-checkbox
                    v-model="filters[5]"
                    label="Finalizado em"
                    hide-details
                    @change="changeHeader(5)"
                  ></v-checkbox>
              </v-col>
          </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-container class="pa-5">
        <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar alunos"
                outlined
                dense
                background-color="transparent"
                hide-details
                @keydown="e => keyObserver(e)"
                @click:append="getPeople()"
          ></v-text-field>
      </v-container>
      <datatable-list-people
        @delete="deletePeople"
        :text="text"
        :headers="headers"
        :items="items"
        v-if="fetch"
        :showSelect="showSelect"
        :displayActionItems="displayActionItems"
        :tableUser="true"
        :pagination="pagination"
        :loading="!fetch"
        @update="pagination = { ...pagination, ...$event}"
        @updateSort="updateSort"
        @reloadStudentList="getPeople()"
      ></datatable-list-people>
      <template v-else>
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="table-heading"
        ></v-skeleton-loader>
      </template>
    </base-card>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import { format, parseISO } from 'date-fns'
import JSpdf from 'jspdf'
import 'jspdf-autotable'
import { getStatusName, getStatusColor } from '@/utils/status'
import {
  isSuperAdmin,
  getSchoolIds,
  getCourseIds,
  hasOrganizationLevel
} from '@/utils/helpers'

export default {
  data: () => ({
    page: {
      title: 'Alunos '
    },
    fetch: false,
    isSearching: false,
    role: '',
    rolesParamsSearch: '',
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      }
    ],
    button: {
      show: false,
      url: '/course/add/',
      text: 'Alunos para aplicação',
      icon: 'mdi-plus'
    },
    text: {
      searchLabel: 'Pesquisar alunos',
      emptyLabel: 'Nenhuma cadastro encontrado',
      addLabel: 'Adicionar Aluno',
      addUrl: '/school/add',
      importLabel: 'Importar Turmas',
      importUrl: '/school/add'
    },
    headers: [
      { id: 0, text: 'Nome', value: 'name', align: 'start' },
      { id: 1, text: 'Status', value: 'status', align: 'start' },
      { id: 6, text: 'Reabrir', value: 'reopen', align: 'start', sortable: false },
      { id: 2, text: 'Nota', value: 'grade', align: 'start', sortable: false },
      { id: 7, text: 'Corrigir', value: 'correct', align: 'start', sortable: false }
    ],
    headersModel: [
      { id: 1, text: 'Status', value: 'status', align: 'start' },
      { id: 2, text: 'Nota', value: 'grade', align: 'start', sortable: false },
      { id: 3, text: 'Tempo gasto', value: 'time_spent', align: 'start', sortable: false },
      { id: 4, text: 'Iniciado', value: 'started_at', align: 'start', sortable: false },
      { id: 5, text: 'Finalizado', value: 'ended_at', align: 'start', sortable: false },
      { id: 6, text: 'Reabrir', value: 'reopen', align: 'start', sortable: false },
      { id: 7, text: 'Corrigir', value: 'correct', align: 'start', sortable: false }
    ],
    filters: [1, 1, 1, 0, 0, 0, 0],
    gradeStatus: '',
    id: '',
    search: '',
    showSelect: false,
    displayActionItems: false,
    items: [],
    dialog: {
      show: false
    },
    courses: [],
    sortBy: '',
    sortDesc: '',
    pagination: {
      page: 1,
      itemsPerPage: 15,
      prev_page_url: false,
      next_page_url: false
    },
    course_ids: []
  }),
  watch: {
    sortBy (newV, oldV) {
      if (newV === oldV) return
      clearTimeout(this._timerId)
      this._timerId = setTimeout(async () => {
        this.getPeople()
      }, 1500)
    },
    sortDesc (newV, oldV) {
      if (newV === oldV) return
      clearTimeout(this._timerId)
      this._timerId = setTimeout(async () => {
        this.getPeople()
      }, 1500)
    },
    'pagination.page' (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getPeople()
      }
    },
    'pagination.itemsPerPage' (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getPeople()
      }
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    getStatusColor,
    getStatusName,
    getCourseIds,
    getSchoolIds,
    isSuperAdmin,
    hasOrganizationLevel,
    findObjectInArrayByID (id, array) {
      return array.find(el => el.id === id)
    },
    changeHeader (id) {
      if (this.findObjectInArrayByID(id, this.headers)) {
        this.headers = this.headers.filter(el => el.id !== id)
      } else {
        this.headers.push(this.findObjectInArrayByID(id, this.headersModel))
      }
    },
    openDialog () {
      this.dialog.show = true
    },
    formatSeconds (sec) {
      if (!sec) return 0
      const date = new Date(0)
      date.setSeconds(sec) // specify value for SECONDS here
      return date.toISOString().substr(11, 8)
    },
    formatDate (d, returnMessage = 'Não iniciado') {
      if (!d) return returnMessage
      return format(parseISO(d), 'dd/MM/yyyy HH:mm')
    },
    formatPeoples (peoples) {
      const arr = []
      if (!peoples.length) return arr
      peoples.forEach((value, index) => {
        const obj = {
          ...value,
          avatar: value.student_avatar,
          name: value.student_name,
          email: value.student_email,
          id: value.student_id,
          courses: value.course_ids,
          status: {
            name: this.getStatusName(value.status),
            color: this.getStatusColor(value.status),
            value: value.status
          },
          reopen: {
            enabled: value.status !== 'NOT_STARTED'
          },
          started_at: this.formatDate(value.started_at),
          ended_at: this.formatDate(value.ended_at, 'Não finalizado'),
          grade: value.grade?.toFixed(2),
          hit_percentage: 0, // value.grade ? ((value.grade / value.questions.length) * 100).toFixed(2) + '%' : '-',
          time_spent: this.formatSeconds(value.time_spent),
          url: (value.status === 'NOT_STARTED') ? null : `/exam/${this.$route.params.id}/application/${this.$route.params.application}/resolution/${value.id}`,
          isSelected: false,
          student_id_for_reopen: value.id,
          correct: {}
        }
        arr.push(obj)
      }
      )
      return arr
    },
    selectCourse (e) {
      this.course_ids = e
      this.getPeople()
    },
    getPeople () {
      if (this.isSearching) return

      this.fetch = false
      this.isSearching = true

      const payload = {
        ...(this.search ? { search: this.search } : {}),
        ...(!this.hasOrganizationLevel() && !this.isSuperAdmin() && this.getCourseIds().length ? { course_ids: this.getCourseIds() } : {}),
        ...(!this.hasOrganizationLevel() && !this.isSuperAdmin() && this.getSchoolIds().length ? { school_ids: this.getSchoolIds() } : {})
      }

      this.$axios
        .post(
            `/organizations/${this.id}` +
            `/lists/exams-applications/${this.$route.params.application}` +
            `/students?page=${this.pagination.page}`,
            payload
        )
        .then(response => {
          this.items = this.formatPeoples(response.data.items)
          const pagination = response.data.pagination
          this.pagination = {
            ...this.pagination,
            total: pagination.total,
            page: pagination.current_page,
            itemsPerPage: pagination.per_page
          }
        })
        .finally(() => {
          this.fetch = true
          this.isSearching = false
        })
    },
    savingPeople () {
      this.dialog.show = false
      this.getPeople()
    },
    formatPeoplesSend (peoples) {
      const arr = []
      if (!peoples.length) return arr
      peoples.forEach((value, index) => {
        const obj = {
          user: {
            id: value.id
          },
          roles: [{ name: this.dialog.role, operation: 'REMOVE' }]
        }

        arr.push(obj)
      })
      return arr
    },
    async deletePeople (people) {
      // this.dialog.show = false
      this.setLoading(true)
      const self = this
      const payload = this.formatPeoplesSend(people)
      this.$axios
        .post(this.dialog.linkSave, { users: payload })
        .then(response => {
          self.$store.dispatch('alert', { msg: 'Remoção bem sucedida!' })
        })
        .catch(error => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', {
                color: 'red',
                msg: errosArray[item][0]
              })
            })
          } else {
            self.$store.dispatch('alert', {
              color: 'red',
              msg: 'Erro na rede, tente novamente!'
            })
          }
        })
        .finally(() => {
          self.setLoading(false)
          self.getPeople()
        })
    },
    updateSort (item, value) {
      if (value === undefined) {
        this.sortBy = ''
        this.sortDesc = ''
      } else {
        if (item === 'column') {
          this.sortBy = value
        }
        if (item === 'desc') {
          this.sortDesc = value
        }
      }
    },
    keyObserver (e) {
      if (e.which === 13) {
        this.getPeople()
      }
    },
    exportPDF () {
      const columns = this.headers.map(header => {
        return {
          title: header.text,
          dataKey: header.value
        }
      })
      const doc = new JSpdf({
        orientation: 'portrait',
        unit: 'in',
        format: 'letter'
      })
      doc.setFontSize(16).text(this.page.title, 0.5, 1.0)
      // create a line under heading
      doc.setLineWidth(0.01).line(0.5, 1.1, 8.0, 1.1)
      doc.setFontSize(12)
      doc.autoTable({
        columns: [
          ...columns
        ],
        body: this.items.map(item => {
          return {
            ...item,
            // TODO - Corrigir imagem das turmas
            // courses: item.courses.map((course, index) => index === 0 ? course.name : ' ' + course.name),
            status: item.status.name
          }
        }),
        styles: { font: 'helvetica' },
        margin: { left: 0.5, top: 1.25 }
      })
      doc.save('alunos.pdf')
    }
  },
  mounted () {
    const rolesParams = this.$route.params.roles

    this.button.url = `/course/people/${this.$route.params.id}/${this.$route.params.course}/${rolesParams}`

    this.breadcrumbs.push(
      {
        text: 'Exame',
        to: `/exam/view/${this.$route.params.id}`,
        disabled: false
      },
      {
        text: 'Aplicação do Exame',
        to: `/exam/application/view/${this.$route.params.id}/${this.$route.params.application}`,
        disabled: false
      },
      {
        text: 'Alunos',
        disabled: true
      }
    )

    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    if (this.id) {
      this.getPeople()
    } else {
      this.$store.dispatch('alert', {
        color: 'red',
        msg: 'Você precisa selecionar uma organização'
      })
    }
  }
}
</script>
